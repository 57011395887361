export const environment = {
  APP_URL: 'https://preprodweb.blueteesgolf.com',
  BASE_API_ENDPOINT: 'https://preprod.blueteesgolf.com/web',
  BASE_IMAGE_ENDPOINT: 'https://blueteesdb.s3.amazonaws.com/bluetees-app',
  GOOGLE_CAPTCHA_KEY: '6Ldq5RUpAAAAANHT2dnTqU3_YZNtv1Crk2nSLnaB',
  AUTH_SECRET_KEY: `R&y57*U*kf1Q6+8web+BGn8Xp7%qHkb%A6L$gv2&xnQ!7mjKDXeG`,
  API_KEY: 'UCCBMXN9wK1-Voc',
  SECRET_KEY: 'bqbGf6WQuRr7yx2a3Snyth64C9miIA',
  API_VERSION: '1.1',
  SIG_VERSION: '2.0',
  SIG_METHOD: 'HmacSHA256',
  RESPONSE_FORMAT: 'JSON',
  TOKEN_SPLIT: '^8luetee5^',
  TOKEN_SECRET: 'hB5i0&BhmWOojpgszvB@yIaENLI0&ZR0HdRX0ZYZAYMB&',
  TOKEN_KEY: 'MyAwesomeWE85kitterKey',
  TOKEN_KEY_SECRET: `knR@hyZ9DGzOAZfHV8wv$RxaeivSGifQTLimbuaV`,
  production: true,
};
